export const theme = {
  colors: {
    primary: "#134b99", //"#0d6efd",
    secondary: "#ffc107",
    warning: "#ffc107",
    success: "198754",
    info: "#0dcaf0",
    warning: "#ffc107",
    danger: "#dc3545",
    light: "#f8f9fa",
    primary60: "#4b8de9", //'#6ea8fe',
    primary80: "#1a68d5", //'#3d8bfd',
  },
  strings: {
    appName: "TipSom-beta",
  },
};

export const defaultRequiredInputFields = {
  VictimProfileForm: {
    initials: { isMandatory: true },
    email: { validations: { email: true } },
    gender: { isMandatory: true },
    race: { isMandatory: true },
    citizenship: { isMandatory: true },
    interviewDate: { isMandatory: true },
    interviewCountry: { isMandatory: true },
    interviewerLocation: { isMandatory: true },
  },
  TipTransitForm: {
    countryOfOrigin: { isMandatory: true },
    countryOfDestination: { isMandatory: true },
  },
  ExploitationForm: {
    subjectToExploitation: { isMandatory: true },
    paidDebt: { isMandatory: true },
    eChildSoldierAge: { validations: { integer: true, min: 1, max: 18 } },
    eChildFromMarriage: { validations: { integer: true, min: 1, max: 20 } },
    exploitationLength: { validations: { integer: true, min: 0 } },
    eOrganSalePrice: { validations: { number: true, min: 0 } },
    debtAmount: { validations: { number: true, min: 0 } },
  },
  CaseDataForm: {
    caseNumber: { isMandatory: true },
    countryFrom: { isMandatory: true },
    countryTo: { isMandatory: true },
    // "placeFrom": {},
  },
  SomVictimProfileForm: {
    initials: { isMandatory: true },
    email: { validations: { email: true } },
    gender: { isMandatory: true },
    race: { isMandatory: true },
    citizenship: { isMandatory: true },
    interviewDate: { isMandatory: true },
    interviewCountry: { isMandatory: true },
    interviewerLocation: { isMandatory: true },
  },
  VictimBulkEntryForm: {
    numberOfVictims: { isMandatory: true },
    interviewDate: { isMandatory: true },
    interviewCountry: { isMandatory: true },
    interviewerLocation: { isMandatory: true },
  },
  SomTransitForm: {
    countryOfOrigin: { isMandatory: true },
    countryOfDestination: { isMandatory: true },
    meansOfTransportation: { isMandatory: true },
  },

  TipSuspectBioForm: {
    // firstName: { isMandatory: true },
    // // surName: { isMandatory: true },
    // dob: { isMandatory: true },
    // gender: { isMandatory: true },
    // race: { isMandatory: true },
    // languages: { isMandatory: true },
    citizenship: { isMandatory: true },
  },
  TipArrestForm: {
    suspectArrested: { isMandatory: true },
  },
  SomArrestForm: {
    suspectArrested: { isMandatory: true },
  },
  ProsecutionForm: {
    caseStatus: { isMandatory: true },
    trialCourt: { isMandatory: true },
  },
  InterviewerProfileForm: {
    firstName: { isMandatory: true },
    familyName: { isMandatory: true },
    position: { isMandatory: true },
    organization: { isMandatory: true },
    address: { isMandatory: true },
    email: { isMandatory: true, validations: { email: true } },
    // country: { isMandatory: true },
    // dataEntryPurpose: { isMandatory: true },
  },
  RegistrationForm: {
    firstName: { isMandatory: true },
    familyName: { isMandatory: true },
    position: { isMandatory: true },
    organization: { isMandatory: true },
    address: { isMandatory: true },
    email: { isMandatory: true, validations: { email: true } },
    // country: { isMandatory: true },
    // dataEntryPurpose: { isMandatory: true },
    password: { isMandatory: true, validations: { password: true } },
    confirmPassword: { isMandatory: true, validations: { confirmPassword: true } },
  },

  AssistanceVictimProfileForm: {
    initials: { isMandatory: true },
    gender: { isMandatory: true },
    race: { isMandatory: true },
    citizenship: { isMandatory: true },
  },
  SomAssistanceSingleVictimProfileForm: {
    initials: { isMandatory: true },
    gender: { isMandatory: true },
    race: { isMandatory: true },
    citizenship: { isMandatory: true },
  },
  SomAssistanceMultipleVictimProfileForm: {
    numberOfVictims: { isMandatory: true },
  },
  AssistanceTypesForm: {
    social_assistance_duration: { isMandatory: true },
  },
  SocioEconomicForm: {
    familyStructure: { isMandatory: true },
    educationLevel: { isMandatory: true },
  },
};

export const matchProfileVariablesToServerColumns = {
  VictimProfileForm: {
    initials: "initials",
    age: "age",
    dob: "dob",
    gender: "gender",
    race: "race",
    religions: "religions",
    languages: "languages",
    email: "email_address",
    citizenship: "citizenship",
    idType: "identification_type",
    idNumber: "identification_number",
    dualCitizenship: "dual_citizenship",
    address: "address",
    lastPlaceOfResidence: "last_place_of_residence",
    countryOfBirth: "country_of_birth",
    placeOfBirth: "place_of_birth",
    interviewDate: "interview_date",
    interviewCountry: "interview_country",
    interviewerLocation: "interview_location",
    additionalRemarks: "additional_remarks",
    latitude: "latitude",
    longitude: "longitude",
    approval: "approval",
    approvalComments: "approval_comments",
  },
  ExploitationForm: {
    v_id: "victim",
    subjectToExploitation: "subject_to_exploitation",
    intentToExploit: "intent_to_exploit",
    exploitationLength: "exploitation_length",
    exploitationAge: "exploitation_age",
    paidDebt: "pay_debt",
    debtAmount: "debt_amount",
    freedMethod: "freed_method",
    eventDescription: "event_description",
    eProstitution: "e_prostitution",
    eOtherSexual: "e_other_sexual",
    eOtherSexualOnline: "e_other_sexual_online",
    eOnlinePorno: "e_online_porno",
    eCriminalActivity: "e_criminal_activity",
    eCriminalActivityType: "e_criminal_activity_type",
    eForcedLabour: "e_forced_labour",
    eForcedLabourIndustry: "e_forced_labour_industry",
    eForcedMarriage: "e_forced_marriage",
    eVictimKnewSpouse: "e_victim_knew_spouse",
    eSpouseNationality: "e_spouse_nationality",
    eBPricePaid: "e_bprice_paid",
    eBPriceAmountKind: "e_bprice_amount_kind",
    eBPriceRecipient: "e_brice_recipient",
    eChildMarriage: "e_child_marriage",
    eChildMarriageReason: "e_child_marriage_reason",
    eVictimPregnancy: "e_victim_pregnancy",
    eChildFromMarriage: "e_children_from_marriage",
    eMaternalHealthIssues: "e_maternal_health_issues",
    eMHealthIssuesDescription: "e_m_health_issues_description",
    eMarriageViolence: "e_marriage_violence",
    eMarriageViolenceType: "e_marriage_violence_type",
    eForcedMilitary: "e_forced_military",
    eForcedMilitaryType: "e_forced_military_type",
    eArmedGroupName: "e_armed_group_name",
    eVictimMilitaryActivities: "e_victim_military_activities",
    eChildSoldier: "e_child_soldier",
    eChildSoldierAge: "e_child_soldier_age",
    eOrganRemoved: "e_organ_removed",
    eBodyPartRemoved: "e_body_part_removed",
    eOperationLocation: "e_operation_location",
    eOperationCountry: "e_operation_country",
    eOrganSalePrice: "e_organ_sale_price",
    eOrganPaidTo: "e_organ_paid_to",
    eRemarks: "e_remarks",
    eRecruitmentType: "e_recruitment_type",
    eRecruiterRelationship: "e_recruiter_relationship",
    eTraffickingMeans: "e_trafficking_means",
  },
  TipTransitForm: {
    v_id: "victim",
    countryOfOrigin: "country_of_origin",
    countryOfDestination: "country_of_dest",
    cityVillageOfDest: "city_village_of_dest",
    cityVillageOfOrigin: "city_village_of_origin",
    remarks: "remarks",
    meansOfTransportation: "transport_means",
  },
  TipSuspectBioForm: {
    case_id: "case",
    firstName: "first_name",
    surName: "last_name",
    dob: "dob",
    age: "age",
    gender: "gender",
    race: "race",
    religions: "religions",
    languages: "languages",
    citizenship: "citizenship",
    dualCitizenship: "dual_citizenship",
    nationality: "nationality",
    idType: "id_type",
    idNumber: "id_number",
    address: "address",
    lastPlaceOfResidence: "last_residence",
    placeOfBirth: "place_of_birth",
    countryOfBirth: "country_of_birth",
    dateOfArrest: "date_of_arrest",
    roleInTrafficking: "role_in_trafficking",
    countryFrom: "traffick_from_country",
    placeFrom: "traffick_from_place",
    countryTo: "traffick_to_country",
    placeTo: "traffick_to_place",
  },
  TipArrestForm: {
    case_id: "case",
    suspectArrested: "suspects_arrested",
    organizedCrime: "org_crime",
    whyNoArrest: "why_no_arrest",
    victimSmuggled: "victim_smuggled",
    howTraffickersOrg: "how_traffickers_org",
    investigationDone: "investigation_done",
    whyNoInvestigation: "why_no_investigation",
    investigationStatus: "investigation_status",
    whyPending: "why_pending",
    withdrawnClosedReason: "withdrawn_closed_reason",
  },
  SomArrestForm: {
    case_id: "case",
    suspectArrested: "suspects_arrested",
    organizedCrime: "org_crime",
    whyNoArrest: "why_no_arrest",
    victimSmuggled: "victim_smuggled",
    howTraffickersOrg: "how_traffickers_org",
    investigationDone: "investigation_done",
    whyNoInvestigation: "why_no_investigation",
    investigationStatus: "investigation_status",
    whyPending: "why_pending",
    withdrawnClosedReason: "withdrawn_closed_reason",
  },
  CaseDataForm: {
    dateOfArrest: "date_of_arrest",
    caseNumber: "case_number",
    countryFrom: "traffick_from_country",
    placeFrom: "traffick_from_place",
    countryTo: "traffick_to_country",
    placeTo: "traffick_to_place",
    approval: "approval",
    approvalComments: "approval_comments",
  },

  SomVictimProfileForm: {
    initials: "initials",
    age: "age",
    dob: "dob",
    gender: "gender",
    race: "race",
    religions: "religions",
    languages: "languages",
    email: "email",
    citizenship: "citizenship",
    idType: "identificationType",
    idNumber: "idNumber",
    dualCitizenship: "",
    address: "address",
    lastPlaceOfResidence: "lastPlaceOfResidence",
    countryOfBirth: "countryOfBirth",
    placeOfBirth: "placeOfBirth",
    interviewDate: "interview_date",
    interviewCountry: "interview_country",
    interviewerLocation: "interview_location",
    additionalRemarks: "additional_remarks",
    latitude: "latitude",
    longitude: "longitude",
    approval: "approval",
    approvalComments: "approval_comments",
  },
  VictimBulkEntryForm: {
    numberOfVictims: "victim_count",
    ages: "ages",
    genders: "genders",
    races: "races",
    religions: "religions",
    languages: "languages",
    countries: "countries_of_origin",
    interviewDate: "interview_date",
    interviewCountry: "interview_country",
    interviewerLocation: "interview_location",
    additionalRemarks: "additional_remarks",
    latitude: "latitude",
    longitude: "longitude",
    approval: "approval",
    approvalComments: "approval_comments",
  },
  SomTransitForm: {
    v_id: "victim",
    // case_id: "",
    countryOfOrigin: "country_of_origin",
    countryOfDestination: "country_of_dest",
    countriesOfTransit: "countries_of_transit",
    cityVillageOfDest: "city_village_of_dest",
    cityVillageOfOrigin: "city_village_of_origin",
    countryOfInterception: "country_of_interception",
    meansOfTransportation: "transport_means",
    remarks: "remarks",
  },
  ProsecutionForm: {
    case_id: "case",
    suspectedTrafficker: "trafficker",
    caseStatus: "status_of_case",
    trialCourt: "trial_court",
    foreignCourtCountry: "trial_court_country",
    courtCaseNumber: "court_case_no",
    verdict: "verdict",
    guiltyVerdict: "guilty_verdict",
    guiltyVerdictReason: "guilty_verdict_reason",
    prosecutionOutcome: "prosecution_outcome",
    acquittalReason: "aquital_reason",
    reviewAppealOutcome: "review_appeal_outcome",
    penalty: "sanction_penalty",
    yearsImposed: "years_imposed",
  },
  InterviewerProfileForm: {
    firstName: "first_name",
    familyName: "last_name",
    position: "position",
    organization: "organization",
    address: "address",
    email: "email_address",
    // country: "country",
    dataEntryPurpose: "data_entry_purpose",
    can_switch_profiles: "can_switch_profiles",
  },
  RegistrationForm: {
    firstName: "first_name",
    familyName: "last_name",
    position: "position",
    organization: "organization",
    address: "address",
    email: "email_address",
    country: "country",
    dataEntryPurpose: "data_entry_purpose",
    password: "password",
    confirmPassword: "confirm_password",
  },
  AssistanceVictimProfileForm: {
    initials: "initials",
    age: "age",
    dob: "dob",
    gender: "gender",
    race: "race",
    religions: "religions",
    languages: "languages",
    email: "email_address",
    citizenship: "citizenship",
    idType: "identification_type",
    idNumber: "identification_number",
    dualCitizenship: "dual_citizenship",
    address: "address",
    lastPlaceOfResidence: "last_place_of_residence",
    countryOfBirth: "country_of_birth",
    placeOfBirth: "place_of_birth",
    latitude: "latitude",
    longitude: "longitude",
  },
  SomAssistanceSingleVictimProfileForm: {
    initials: "initials",
    age: "age",
    dob: "dob",
    gender: "gender",
    race: "race",
    religions: "religions",
    languages: "languages",
    email: "email",
    citizenship: "citizenship",
    idType: "identificationType",
    idNumber: "idNumber",
    dualCitizenship: "",
    address: "address",
    lastPlaceOfResidence: "lastPlaceOfResidence",
    countryOfBirth: "countryOfBirth",
    placeOfBirth: "placeOfBirth",
    additionalRemarks: "additionalRemarks",
    latitude: "latitude",
    longitude: "longitude",
  },
  SomAssistanceMultipleVictimProfileForm: {
    numberOfVictims: "victim_count",
    ages: "ages",
    genders: "genders",
    races: "races",
    religions: "religions",
    languages: "languages",
    countries: "countries_of_origin",
    additionalRemarks: "additional_remarks",
    latitude: "latitude",
    longitude: "longitude",
  },
  AssistanceTypesForm: {
    v_id: "victim",
    social_assistance_duration: "social_assistance_duration",
    social_assistance_duration_time_unit: "social_assistance_duration_time_unit",
    social_assistance_providers: "social_assistance_providers",
    social_assistance_providers_other: "social_assistance_providers_other",

    medical_rehabilitation_assistance_duration: "medical_rehabilitation_assistance_duration",
    medical_rehabilitation_assistance_duration_time_unit: "medical_rehabilitation_assistance_duration_time_unit",
    medical_rehabilitation_assistance_providers: "medical_rehabilitation_assistance_providers",
    medical_rehabilitation_assistance_providers_other: "medical_rehabilitation_assistance_providers_other",

    halfway_house_assistance_duration: "halfway_house_assistance_duration",
    halfway_house_assistance_duration_time_unit: "halfway_house_assistance_duration_time_unit",
    halfway_house_assistance_providers: "halfway_house_assistance_providers",
    halfway_house_assistance_providers_other: "halfway_house_assistance_providers_other",

    vocational_training_assistance_duration: "vocational_training_assistance_duration",
    vocational_training_assistance_duration_time_unit: "vocational_training_assistance_duration_time_unit",
    vocational_training_assistance_providers: "vocational_training_assistance_providers",
    vocational_training_assistance_providers_other: "vocational_training_assistance_providers_other",

    legal_assistance_duration: "legal_assistance_duration",
    legal_assistance_duration_time_unit: "legal_assistance_duration_time_unit",
    legal_assistance_providers: "legal_assistance_providers",
    legal_assistance_providers_other: "legal_assistance_providers_other",

    financial_assistance_duration: "financial_assistance_duration",
    financial_assistance_duration_time_unit: "financial_assistance_duration_time_unit",
    financial_assistance_providers: "financial_assistance_providers",
    financial_assistance_providers_other: "financial_assistance_providers_other",

    immigration_assistance_duration: "immigration_assistance_duration",
    immigration_assistance_duration_time_unit: "immigration_assistance_duration_time_unit",
    immigration_assistance_providers: "immigration_assistance_providers",
    immigration_assistance_providers_other: "immigration_assistance_providers_other",

    housing_allowance_duration: "housing_allowance_duration",
    housing_allowance_duration_time_unit: "housing_allowance_duration_time_unit",
    housing_allowance_providers: "housing_allowance_providers",
    housing_allowance_providers_other: "housing_allowance_providers_other",

    shelter_assistance_duration: "shelter_assistance_duration",
    shelter_assistance_duration_time_unit: "shelter_assistance_duration_time_unit",
    shelter_assistance_providers: "shelter_assistance_providers",
    shelter_assistance_providers_other: "shelter_assistance_providers_other",

    income_generating_project_assistance_duration: "income_generating_project_assistance_duration",
    income_generating_project_assistance_duration_time_unit: "income_generating_project_assistance_duration_time_unit",
    income_generating_project_assistance_providers: "income_generating_project_assistance_providers",
    income_generating_project_assistance_providers_other: "income_generating_project_assistance_providers_other",

    medical_assistance_duration: "medical_assistance_duration",
    medical_assistance_duration_time_unit: "medical_assistance_duration_time_unit",
    medical_assistance_providers: "medical_assistance_providers",
    medical_assistance_providers_other: "medical_assistance_providers_other",

    education_assistance_duration: "education_assistance_duration",
    education_assistance_duration_time_unit: "education_assistance_duration_time_unit",
    education_assistance_providers: "education_assistance_providers",
    education_assistance_providers_other: "education_assistance_providers_other",

    community_based_assistance_duration: "community_based_assistance_duration",
    community_based_assistance_duration_time_unit: "community_based_assistance_duration_time_unit",
    community_based_assistance_providers: "community_based_assistance_providers",
    community_based_assistance_providers_other: "community_based_assistance_providers_other",
  },
  MultiAssistanceTypesForm: {
    v_id: "multi_victim",
    social_assistance_duration: "social_assistance_duration",
    social_assistance_duration_time_unit: "social_assistance_duration_time_unit",
    social_assistance_providers: "social_assistance_providers",
    social_assistance_providers_other: "social_assistance_providers_other",

    medical_rehabilitation_assistance_duration: "medical_rehabilitation_assistance_duration",
    medical_rehabilitation_assistance_duration_time_unit: "medical_rehabilitation_assistance_duration_time_unit",
    medical_rehabilitation_assistance_providers: "medical_rehabilitation_assistance_providers",
    medical_rehabilitation_assistance_providers_other: "medical_rehabilitation_assistance_providers_other",

    halfway_house_assistance_duration: "halfway_house_assistance_duration",
    halfway_house_assistance_duration_time_unit: "halfway_house_assistance_duration_time_unit",
    halfway_house_assistance_providers: "halfway_house_assistance_providers",
    halfway_house_assistance_providers_other: "halfway_house_assistance_providers_other",

    vocational_training_assistance_duration: "vocational_training_assistance_duration",
    vocational_training_assistance_duration_time_unit: "vocational_training_assistance_duration_time_unit",
    vocational_training_assistance_providers: "vocational_training_assistance_providers",
    vocational_training_assistance_providers_other: "vocational_training_assistance_providers_other",

    legal_assistance_duration: "legal_assistance_duration",
    legal_assistance_duration_time_unit: "legal_assistance_duration_time_unit",
    legal_assistance_providers: "legal_assistance_providers",
    legal_assistance_providers_other: "legal_assistance_providers_other",

    financial_assistance_duration: "financial_assistance_duration",
    financial_assistance_duration_time_unit: "financial_assistance_duration_time_unit",
    financial_assistance_providers: "financial_assistance_providers",
    financial_assistance_providers_other: "financial_assistance_providers_other",

    immigration_assistance_duration: "immigration_assistance_duration",
    immigration_assistance_duration_time_unit: "immigration_assistance_duration_time_unit",
    immigration_assistance_providers: "immigration_assistance_providers",
    immigration_assistance_providers_other: "immigration_assistance_providers_other",

    housing_allowance_duration: "housing_allowance_duration",
    housing_allowance_duration_time_unit: "housing_allowance_duration_time_unit",
    housing_allowance_providers: "housing_allowance_providers",
    housing_allowance_providers_other: "housing_allowance_providers_other",

    shelter_assistance_duration: "shelter_assistance_duration",
    shelter_assistance_duration_time_unit: "shelter_assistance_duration_time_unit",
    shelter_assistance_providers: "shelter_assistance_providers",
    shelter_assistance_providers_other: "shelter_assistance_providers_other",

    income_generating_project_assistance_duration: "income_generating_project_assistance_duration",
    income_generating_project_assistance_duration_time_unit: "income_generating_project_assistance_duration_time_unit",
    income_generating_project_assistance_providers: "income_generating_project_assistance_providers",
    income_generating_project_assistance_providers_other: "income_generating_project_assistance_providers_other",

    medical_assistance_duration: "medical_assistance_duration",
    medical_assistance_duration_time_unit: "medical_assistance_duration_time_unit",
    medical_assistance_providers: "medical_assistance_providers",
    medical_assistance_providers_other: "medical_assistance_providers_other",

    education_assistance_duration: "education_assistance_duration",
    education_assistance_duration_time_unit: "education_assistance_duration_time_unit",
    education_assistance_providers: "education_assistance_providers",
    education_assistance_providers_other: "education_assistance_providers_other",

    community_based_assistance_duration: "community_based_assistance_duration",
    community_based_assistance_duration_time_unit: "community_based_assistance_duration_time_unit",
    community_based_assistance_providers: "community_based_assistance_providers",
    community_based_assistance_providers_other: "community_based_assistance_providers_other",
  },
  SocioEconomicForm: {
    v_id: "victim",
    familyStructure: "family_structure",
    livingWith: "living_with",
    lastOccupation: "last_occupation",
    violence: "violence_prior",
    educationLevel: "education_level",
  },
};

export const navigationRoutes = {
  home: "/",
  dashboard: "/dashboard",
  about: "/about",
  contact: "/contact",
  login: "/login",
  logout: "/logout",
  forgotPassword: "/forgot-password",
  register: "/register",
  search: "/search",

  tipCases: "/tip-cases",
  tipCasesNew: "/tip-cases/new",

  somCases: "/som-cases",
  somCasesNew: "/som-cases/new",

  findHelp: "/find-help",
  settings: "/settings",
};
