import React, { useContext } from "react";
import { Card, CardContent, CardActions, Typography, Divider, IconButton, CardActionArea, CardHeader, Avatar } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Person, PersonAdd, RemoveRedEye } from '@mui/icons-material';
import styles from './MyInputs/styles';
import { AuthContext } from "../App";

const MyTipAddSuspectCard = ({ victim, addSuspectPressed }) => {
  const { strings, dbLookups, interviewer } = useContext(AuthContext);

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: theme => theme.palette.primary.main, color: theme => theme.palette.secondary.main }} aria-label="">
            <Person />
          </Avatar>
        }
        // action={
        //   <IconButton aria-label="settings">
        //     <MoreVertIcon />
        //   </IconButton>
        // }
        title={`${victim.first_name || ""} ${victim.last_name || ""}`}
        subheader={victim.date_of_arrest}
      />
      <CardContent>
        {dbLookups.countrys.find(country => country.key == victim.citizenship)?.value}
        <Typography>{`ID: ${victim.id_number || ""}`}</Typography>

      </CardContent>
      <CardActions sx={{ display: 'flex', justifyContent: 'flex-end', }}>
        <IconButton
          aria-label="view"
          onClick={(e) => {
            e.stopPropagation(); // Prevent triggering the card click event
            // viewPressed(null, victim.case, true);
            
            addSuspectPressed(victim.id);
          }}
          sx={styles.cardIconButton}
        >
          <PersonAdd />
        </IconButton>
        




      </CardActions>
    </Card>

  )
};

export default MyTipAddSuspectCard;
