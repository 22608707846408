import React, { useContext, useEffect, useState } from "react";
import { getAssistanceVictims, getSomSingleVictims, getTipCases, getVictims } from "../api/api";
// import { storeData, retrieveData } from "../utils/offlineStorage";
import { AuthContext } from "../App";
import { navigationRoutes, theme } from "../utils/variables";
import { Box, Button, Container, Grid, IconButton, Modal, Paper, Slide, Typography } from "@mui/material";
import { AddCircle, Close, Search } from "@mui/icons-material";
import styles from "../components/MyInputs/styles";
import { useNavigate } from "react-router-dom";
import MyVictimCard from "../components/MyVictimCard";
import MyLoadingBar from "../components/MyLoadingBar";
import MyCaseCard from "../components/MyCaseCard";
import imageLogo from "../assets/icon.png";
import MyTipCaseNoSearchForm from "../components/searchForms/MyTipCaseNoSearchForm";
import { DataGrid } from "@mui/x-data-grid";
import MyCard from "../components/MyInputs/MyCard";
import MyCardTitle from "../components/MyInputs/MyCardTitle";
import TipCasesAddScreen from "./TipCasesAddScreen";

const TipCasesScreen = ({ navigation }) => {
  const { strings, interviewer } = useContext(AuthContext);
  const [refreshing, setRefreshing] = useState(false);
  const [searchFormOpen, setSearchFormOpen] = useState(false);
  const [caseModalOpen, setCaseModalOpen] = useState(false);
  const [victims, setVictims] = useState([]);
  const [cases, setCases] = useState([]);
  const [victimsApproval, setVictimsApproval] = useState([]);
  const [casesApproval, setCasesApproval] = useState([]);
  const [selectedVictim, setSelectedVictim] = useState();
  const [selectedCase, setSelectedCase] = useState();
  const navigate = useNavigate();

  const caseColumns = [
    // { field: "id", headerName: "ID", width: 50 },
    { field: "case_number", headerName: "Case Number", width: 130 },
    { field: "date_of_arrest", headerName: "Date Of Arrest", width: 130 },
    { field: "suspects", headerName: "Suspects", width: 130, valueGetter: (params) => params.length ?? 0 },
    { field: "prosecutions", headerName: "Prosecutiuons", width: 130, valueGetter: (params) => params.length ?? 0 },
    { field: "investigations", headerName: "Investigations", width: 130, valueGetter: (params) => params.length ?? 0 },
  ];

  const paginationModel = { page: 0, pageSize: 20 };

  const handleCaseRowClick = (params, event) => {
    console.log(params);
    setSelectedCase(params.row);
    setCaseModalOpen(true);
  };
  const fetchCases = async () => {
    try {
      setRefreshing(true);
      const dataFromAPI = await getTipCases();
      console.log("dataFromAPI, ", dataFromAPI);
      setCases(dataFromAPI.slice(0, 12));
      setCasesApproval(dataFromAPI);
    } catch (error) {
      console.log(error);
    } finally {
      setRefreshing(false);
    }
  };

  const fetchVictims = async () => {
    try {
      setRefreshing(true);

      const dataFromAPI = await getVictims();
      console.log(dataFromAPI);
      setVictims(dataFromAPI.victims);
      // await storeData("victims", dataFromAPI);
    } catch (error) {
      console.log(error);
      // const offlineData = await retrieveData("victims");
      // if (offlineData) {
      //   setVictims(offlineData);
      //   setRefreshing(false);
      // }
    } finally {
      setRefreshing(false);
    }
  };

  const fetchAssistanceVictims = async () => {
    try {
      setRefreshing(true);

      const dataFromAPI = await getAssistanceVictims();
      console.log(dataFromAPI);
      setVictims(dataFromAPI.victims);
      // await storeData("victims", dataFromAPI);
    } catch (error) {
      console.log(error);
      // const offlineData = await retrieveData("victims");
      // if (offlineData) {
      //   setVictims(offlineData);
      //   setRefreshing(false);
      // }
    } finally {
      setRefreshing(false);
    }
  };

  useEffect(() => {
    interviewer?.data_entry_purpose?.id == 1
      ? fetchVictims()
      : interviewer?.data_entry_purpose?.id == 4
      ? fetchAssistanceVictims()
      : fetchCases();
  }, []);

  const loadVictimData = () => {
    fetchVictims();
  };

  const handleAddVictim = () => {
    navigate(navigationRoutes.tipCasesNew, { state: { isCreateMode: true } });
  };

  const goToSearch = () => {
    navigate(navigationRoutes.search, { state: { tipCaseSearch: true } });
  };

  const handleViewPressed = (victim, caseData) => {
    navigate(navigationRoutes.tipCasesNew, { state: { victim: victim, caseData: caseData, isViewMode: true } });
  };
  const handleModifyPressed = (victim, caseData) => {
    navigate(navigationRoutes.tipCasesNew, { state: { victim: victim, caseData: caseData, isUpdateMode: true } });
  };

  return (
    <Container>
      <MyLoadingBar loading={refreshing} />
      <MyTipCaseNoSearchForm open={searchFormOpen} handleOk={() => setConsentGiven(true)} handleCancel={() => setSearchFormOpen(false)} />

      <Box sx={styles.addCaseButtonBox}>
        {interviewer?.data_entry_purpose?.id == 3 && (
          <Button
            variant="secondary"
            icon="account-arrow-right"
            sx={{ mr: 2 }}
            startIcon={<Search />}
            onClick={() => setSearchFormOpen(true)}
          >
            {strings.addTip.searchCaseNumber}
          </Button>
        )}
        <Button variant="contained" icon="account-arrow-right" startIcon={<AddCircle />} onClick={handleAddVictim}>
          {interviewer?.data_entry_purpose?.id == 1 || interviewer?.data_entry_purpose?.id == 4
            ? strings.addTip.addNewVictim
            : strings.addTip.addNewCase}
        </Button>
      </Box>

      <Grid container spacing={2}>
        {cases.map((caseData) => (
          <Grid item xs={12} sm={6} md={4} lg={3} mb={4} key={caseData.id}>
            <MyCaseCard caseData={caseData} viewPressed={handleViewPressed} modifyPressed={handleModifyPressed} />
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={2}>
        {victims.map((victim) => (
          <Grid item xs={12} sm={6} md={4} lg={3} mb={4} key={victim.id}>
            <MyVictimCard victim={victim} viewPressed={handleViewPressed} modifyPressed={handleModifyPressed} />
          </Grid>
        ))}
      </Grid>

      {casesApproval.length > 0 && (
        <MyCard title={"strings.caseData.allCases"}>
          <MyCardTitle title={"Cases Approval"} />
          <Grid container spacing={2} sx={{ m: 1, ml: 2 }}>
            <Paper sx={{ width: "100%", overflowX: "auto" }}>
              <DataGrid
                rows={casesApproval}
                columns={caseColumns}
                initialState={{ pagination: { paginationModel } }}
                pageSizeOptions={[5, 10, 25, 50]}
                disableRowSelectionOnClick
                onRowClick={handleCaseRowClick}
                sx={{
                  "& .MuiDataGrid-columnHeaders": {
                    backgroundColor: "#1976d2", // Set your desired color here
                    // color: "#fff", // Optional: Set text color
                    fontSize: "16px", // Optional: Adjust font size
                    fontWeight: "bold",
                  },
                }}
              />
            </Paper>
          </Grid>
        </MyCard>
      )}

      {cases.length == 0 && victims.length == 0 && (
        <>
          <Box sx={{ ...styles.logoBox, height: "80vh" }}>
            <img src={imageLogo} width={150} alt={""} loading="lazy" />
            <Typography variant="p" sx={{ mt: 3 }}>
              {strings.notifications.pleaseCreateANewCase}
            </Typography>
          </Box>
        </>
      )}

      <Modal
        open={caseModalOpen}
        onClose={(_, reason) => /*reason !== "backdropClick" &&*/ setCaseModalOpen(false)}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} // Center modal
      >
        <Slide direction="up" in={caseModalOpen}>
          <Box sx={innerStyles.box}>
            <IconButton
              aria-label="close"
              onClick={() => setCaseModalOpen(false)}
              title="Close"
              sx={{
                position: "absolute",
                right: 1,
                top: 1,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
            <TipCasesAddScreen d={{ victim: selectedVictim, caseData: selectedCase, isUpdateMode: true }} />
          </Box>
        </Slide>
      </Modal>
    </Container>
  );
};

const innerStyles = {
  checkBoxStyles: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)", // light gray background on hover
    },
    py: 2,
  },
  box: {
    position: "relative",
    width: "80%",
    // maxWidth: 400,
    overflowY: "auto",
    maxHeight: "80vh",
    bgcolor: "background.paper",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
    p: 4,
  },
};
export default TipCasesScreen;
