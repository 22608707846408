import React, { useContext, useState, useRef, useEffect } from "react";
import { AuthContext } from "../../App";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import styles from "./styles";
import MyTextInput from "./MyTextInput";
import { scrollToComponent } from "../../utils/inputFieldValidations";
import { Typography } from "@mui/material";

const MySelectList = ({ d, overrideLabel, ...otherProps }) => {
  var dbLookupName = d.dbLookupName || d.name;
  var stringName = d.stringName || d.name;
  const [showOtherField, setShowOtherField] = useState(false);
  const [defaultOption, setDefaultOption] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState(null);
  const componentRef = useRef(null);

  const { strings } = useContext(AuthContext);

  const handleChange = (val) => {
    // const selectedName = d.dbLookups?.[dbLookupName]?.find((item) => item.key === val);
    if (val?.value?.toLowerCase().startsWith(strings?.dataValidations?.otherField?.toLowerCase())) {
      setShowOtherField(true);
    } else {
      setShowOtherField(false);
    }
    d.updateFormData(d.name, val?.key || '');
    setValue(val);
  };


  useEffect(() => {
    const defaultOption = d.dbLookups[dbLookupName].find((item) => item.key == d.profile[d.name]);
    if (defaultOption) setValue(defaultOption);
  }, [d.profile[d.name]]);


  var values;
  if (d.overrideValues) values = d.overrideValues;
  else values = d.dbLookups[dbLookupName];

  var label = overrideLabel || d.strings?.[stringName];
  label = d.formValidation?.[d.name]?.["isMandatory"] ? "* " + label : label;

  var longLabel = label?.length > 50;



  scrollToComponent(d.formValidation?.[d.name], d.scrollViewRef, componentRef, d.scrollViewHeight);

  return (
    <div
      ref={componentRef}
      style={{
        ...(d.noStyling ? null : styles.mySelectList),
        ...(d.formValidation?.[d.name]?.["isMandatory"] ? styles.mySelectListErrors : {}),
      }}
    >

      {longLabel ? (
        <Typography style={styles.MyTextLabel}>
          {label}
        </Typography>
      ) : (
        <></>
      )}

      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          handleChange(newValue)
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={values}
        getOptionLabel={(option) => option.value}
        renderInput={(params) => <TextField {...params} label={longLabel ? null : label} />}
      />

      {showOtherField ? (
        <div style={{ marginTop: 32 }}>
          <MyTextInput
            d={{ ...d, name: d.name + "Other", noStyling: true }}
            overrideLabel={strings?.dataValidations?.enterOtherField + " " + d.strings[stringName]}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default MySelectList;
