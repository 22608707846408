import React, { useContext, useEffect, useState, useRef } from "react";
// import {
//   clearOfflineModeVariables,
//   getOfflineModeVariables,
//   saveOfflineData,
//   setOfflineModeVariables,
// } from "../../utils/offlineStorage";
import { AuthContext } from "../../App";
import { globalUpdateFormData, handleDataValidation } from "../../utils/inputFieldValidations";
import MySnackBar from "../MyInputs/MySnackBar";
import { getJsonFromProfile, getProfileFromJson } from "../../utils/myFunctions";
import { Box, Button, Card, CardContent, CardMedia, Container, Grid, Modal, Slide, Tab, Tabs, Typography } from "@mui/material";
import styles from "../MyInputs/styles";
import MyCard from "../MyInputs/MyCard";
import MyLoadingBar from "../MyLoadingBar";
import MyTabPanel from "../MyInputs/MyTabPanel";
import MyGeneralForm from "./MyGeneralForm";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { PersonAdd, PersonSearch, TaskAlt } from "@mui/icons-material";
import MyTraffickerSearchForm from "../searchForms/MyTraffickerSearchForm";
import MySmugglerSearchForm from "../searchForms/MySmugglerSearchForm";

const MyMultipleGeneralForms = ({ onNext, FormFields, d, isSomForm }) => {
  const { strings, dbLookups, globalAppVariables, interviewer } = useContext(AuthContext);

  const [selectedTab, setSelectedTab] = useState(0);
  const [formsUploaded, setFormsUploaded] = useState({});
  const [formsData, setFormsData] = useState();
  const [searchOpen, setSearchOpen] = useState(false);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleCancel = () => {
    setSearchOpen(false);
  };

  const handleTabClose = (index) => {
    const newTabTitles = tabTitles.filter((item, i) => i !== index);
    setTabTitles(newTabTitles);
    setSelectedTab(0);
  };

  const handleAddSuspect = (formId) => {
    setTabTitles([...tabTitles, { title: "Suspect", formId: formId }]);
    setSelectedTab(tabTitles.length);

    setSearchOpen(false);
  };

  const goNextTab = (index) => {
    // setSelectedTab(index + 1);
    // window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const [tabTitles, setTabTitles] = useState([{ title: "Suspect" }]);

  useEffect(() => {
    if (d.isUpdateMode || d.isViewMode) {
      setTabTitles(d.formIds);
    }
  }, [d.isUpdateMode || d.isViewMode]);

  const data = {
    ...d,
    formsUploaded: formsUploaded,
    setFormsUploaded: setFormsUploaded,

    getFormData: formsData,
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Box>
        <Box position="sticky" top={49} style={{ borderBottom: "1px solid #ccc", zIndex: "10" }} bgcolor="background.paper">
          <Box sx={{ overflowX: "auto", whiteSpace: "nowrap" }}>
            <Tabs
              value={selectedTab}
              onChange={handleChange}
              variant="scrollable"
              // sx={{ width: '100%', maxWidth: '100%' }}
            >
              {tabTitles.map((item, index) => (
                // <Tab key={index} label={`${item.title} #${index + 1} ${formsUploaded?.[index] ? '✅' : ''}`} />
                <Tab
                  key={index}
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      {/* {`${item.title} ${index + 1}`} */}
                      {`${strings.addTip.suspect} ${index + 1}`}
                      {formsUploaded?.[index] && <TaskAlt sx={{ ml: 1, color: "green" }} />}
                      {/* {selectedTab === index &&
                          <IconButton
                            size="small"
                            onClick={(event) => {
                              event.stopPropagation(); // Prevent tab change on close button click
                              handleTabClose(index);
                            }}
                            sx={{ ml: 1 }}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        } */}
                    </Box>
                  }
                />
              ))}
              {!d.isViewMode && (
                <>
                  <Tab
                    onClick={() => {
                      setTabTitles([...tabTitles, { title: "Suspect" }]);
                      setSelectedTab(tabTitles.length);
                    }}
                    label={
                      <Box>
                        <PersonAdd />
                      </Box>
                    }
                  />
                  <Tab
                    onClick={() => {
                      setSearchOpen(true);
                      // setSelectedTab(tabTitles.length);
                    }}
                    label={
                      <Box>
                        <PersonSearch />
                      </Box>
                    }
                  />
                </>
              )}
            </Tabs>
          </Box>
        </Box>

        {tabTitles.map((item, index) => (
          <MyTabPanel key={index} value={selectedTab} index={index}>
            <MyGeneralForm
              onNext={() => goNextTab(index)}
              FormFields={FormFields}
              d={{ ...data, formIndex: index, formId: d?.formIds?.[index] || item?.formId }}
            />
          </MyTabPanel>
        ))}
      </Box>

      <Modal
        open={searchOpen}
        onClose={(_, reason) => handleCancel()}
        aria-labelledby="custom-modal-title"
        aria-describedby="custom-modal-description"
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} // Center modal
      >
        <Slide direction="up" in={searchOpen}>
          <Box sx={innerStyles.box}>
            {isSomForm ? (
              <MySmugglerSearchForm addSuspect={true} onAddSuspect={(formId) => handleAddSuspect(formId)} />
            ) : (
              <MyTraffickerSearchForm addSuspect={true} onAddSuspect={(formId) => handleAddSuspect(formId)} />
            )}
          </Box>
        </Slide>
      </Modal>
    </Container>
  );
};

const innerStyles = {
  checkBoxStyles: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)", // light gray background on hover
    },
    py: 2,
  },
  box: {
    position: "relative",
    width: "80%",
    overflowY: "auto",
    // maxWidth: 400,
    maxHeight: "80vh",
    bgcolor: "background.paper",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
    p: 4,
  },
};

export default MyMultipleGeneralForms;
