import React, { useEffect, useContext, useState, useRef } from "react";
import MyTextInput from "../MyInputs/MyTextInput";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { AuthContext } from "../../App";
import MySnackBar from "../MyInputs/MySnackBar";
import MyCard from "../MyInputs/MyCard";
import MyRadioGroup from "../MyInputs/MyRadioGroup";
import MyDatePickerInput from "../MyInputs/MyDatePicker";
import MySelectList from "../MyInputs/MySelectList";
import MyMultipleSelectList from "../MyInputs/MyMultipleSelectList";
import MyCardTitle from "../MyInputs/MyCardTitle";
import { addVictim, fetchVictimDetails, updateVictim } from "../../api/api";
import MyFormsGridItem from "../MyInputs/MyFormsGridItem";

const MyVictimProfileForm = ({ d }) => {
  const formName = "VictimProfileForm";
  const { strings } = useContext(AuthContext);

  useEffect(() => {
    if (!d.isProfileSet) {
      d.setProfile({
        initials: "",
        age: "",
        dob: "",
        gender: "",
        race: "",
        religions: [],
        languages: [],
        email: "",
        citizenship: "",
        idType: [],
        idNumber: "",
        dualCitizenship: "",
        address: "",
        lastPlaceOfResidence: "",
        countryOfBirth: "",
        placeOfBirth: "",
        interviewDate: "",
        interviewCountry: "",
        interviewerLocation: "",
        additionalRemarks: "",
        latitude: d.profile?.latitude,
        longitude: d.profile?.longitude,
        approval: "",
        approvalComments: "",
      })
    }

    d.setStrings(strings.victim)
    d.setFormName(formName)
    d.setSetShouldSetVictimId(true);

    d.setIsProfileSet(true);
    d.setGetFormData(() => fetchVictimDetails)
    d.setPutFormData(() => updateVictim)
    d.setPostFormData(() => addVictim)

  }, []);

  if (!d.isProfileSet) return <div></div>

  return (
    <div>

      <MyCard>
        <MyCardTitle title={strings.victim.bioData} />
        <Grid container>
          <MyFormsGridItem>
            <MyTextInput d={{ name: "initials", ...d }} />
            {/* <MyTextInput d={{ name: "email", ...d }} inputMode="email" /> */}
            {/* <MyDatePickerInput d={{ name: "dob", ...d }} /> */}
            <MySelectList d={{ name: "race", ...d, dbLookupName: "races" }} />
            <MyMultipleSelectList d={{ name: "languages", ...d, dbLookupName: "languages" }} />
          </MyFormsGridItem>
          <MyFormsGridItem>
            {/* <Box height={90} /> */}
            <MySelectList d={{ name: "gender", ...d, dbLookupName: "genders" }} />
            <MyMultipleSelectList d={{ name: "religions", ...d, dbLookupName: "religions" }} />
          </MyFormsGridItem>

        </Grid>
      </MyCard>

      <MyCard>
        <MyCardTitle title={strings.victim.identifierData} />
        <Grid container>
          <MyFormsGridItem><MySelectList d={{ name: "citizenship", ...d, dbLookupName: "countrys" }} /></MyFormsGridItem>
          {/* <MyMultipleSelectList d={{ name: "idType", ...d, dbLookupName: "idTypes" }} /> */}
          {/* <MyTextInput d={{ name: "idNumber", ...d }} /> */}
          <MyFormsGridItem><MySelectList d={{ name: "dualCitizenship", ...d, dbLookupName: "countrys" }} /></MyFormsGridItem>
          {/* <MyTextInput d={{ name: "address", ...d }} /> */}
          {/* <MyFormsGridItem><MySelectList d={{ name: "lastPlaceOfResidence", ...d, dbLookupName: "countrys" }} /></MyFormsGridItem> */}
          {/* <MyFormsGridItem></MyFormsGridItem> */}
          <MyFormsGridItem><MySelectList d={{ name: "countryOfBirth", ...d, dbLookupName: "countrys" }} /></MyFormsGridItem>
          {/* <MyFormsGridItem><MyTextInput d={{ name: "placeOfBirth", ...d }} /></MyFormsGridItem> */}
        </Grid>
      </MyCard>

      <MyCard>
        <MyCardTitle title={strings.victim.interviewData} />
        <Grid container>
          <MyFormsGridItem><MyDatePickerInput d={{ name: "interviewDate", ...d, defaultValue: new Date() }} /></MyFormsGridItem>
          <MyFormsGridItem><MySelectList d={{ name: "interviewCountry", ...d, dbLookupName: "countrys", stringName: "interviewerCountry" }} /></MyFormsGridItem>
          <MyFormsGridItem><MyTextInput d={{ name: "interviewerLocation", ...d }} /></MyFormsGridItem>
          <MyFormsGridItem><MyTextInput d={{ name: "additionalRemarks", ...d }} multiline rows={4} /></MyFormsGridItem>
        </Grid>
      </MyCard>

      {d.interviewer?.can_switch_profiles && 
        <MyCard>
          <MyCardTitle title={strings.victim.approval} />
          <Grid container>
            <MyFormsGridItem><MySelectList d={{ name: "approval", ...d, dbLookupName: "approvalStatuses"}} overrideLabel={strings.victim.approval} /></MyFormsGridItem>
            <MyFormsGridItem><MyTextInput d={{ name: "approvalComments", ...d }} overrideLabel={strings.victim.approvalComments}   multiline rows={4} /></MyFormsGridItem>
          </Grid>
        </MyCard>
      }
    </div>
  );
};

export default MyVictimProfileForm;
