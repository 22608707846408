import React, { useContext, useState } from "react";
import { Modal, Box, Typography, FormControlLabel, Checkbox, Button, Divider, Fade, List, ListItem, ListItemText, Tabs, Tab } from "@mui/material";
import { AuthContext } from "../../App";
import Slide from "@mui/material/Slide";
import MyTabPanel from "../MyInputs/MyTabPanel";
import MyVictimSearchForm from "./MyVictimSearchForm";
import MySomVictimSearchForm from "./MySomVictimSearchForm";
import MyTipCaseSearchForm from "./MyTipCaseSearchForm";
import MySomCaseSearchForm from "./MySomCaseSearchForm";
import MyTraffickerSearchForm from "./MyTraffickerSearchForm";
import MySmugglerSearchForm from "./MySmugglerSearchForm";

const MySomCaseNoSearchForm = ({ open, handleOk, handleCancel, isSomConsent }) => {
  const { strings, interviewer } = useContext(AuthContext);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const [tabTitles] = useState(
    interviewer?.data_entry_purpose?.id == 1
      ? [
          { key: "tipVictim", title: strings.appMenu.tip },
          { key: "somVictim", title: strings.appMenu.som },
        ]
      : interviewer?.data_entry_purpose?.id == 2
      ? [
          { key: "tipCase", title: strings.addTip.tipCase },
          { key: "somCase", title: strings.addSom.somCase },
          { key: "tipSuspect", title: strings.addTip.suspects },
          { key: "somSuspect", title: strings.addSom.suspects },
        ]
      : interviewer?.data_entry_purpose?.id == 3
      ? [
          // { key: "tipCase", title: strings.addTip.tipCase },
          { key: "somCase", title: strings.addSom.somCase },
          // { key: "tipSuspect", title: strings.addTip.suspects },
          // { key: "somSuspect", title: strings.addSom.suspects },
        ]
      : [
          { key: "tipVictim", title: strings.appMenu.tip },
          { key: "somVictim", title: strings.appMenu.som },
        ]
  );

  return (
    <Modal
      open={open}
      onClose={(_, reason) => handleCancel()}
      aria-labelledby="custom-modal-title"
      aria-describedby="custom-modal-description"
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} // Center modal
    >
      <Slide direction="up" in={open}>
        <Box sx={innerStyles.box}>
          <Box>
            <Box position="sticky" top={0} style={{ borderBottom: "1px solid #ccc", zIndex: "10" }} bgcolor="background.paper">
              <Tabs value={selectedTab} onChange={handleChange} centered>
                {tabTitles.map((item, index) => (
                  <Tab key={index} label={<Box sx={{ display: "flex", alignItems: "center" }}>{item.title}</Box>} />
                ))}
              </Tabs>
            </Box>
            {interviewer?.data_entry_purpose?.id == 1 ? (
              <>
                <MyTabPanel value={selectedTab} index={0}>
                  <MyVictimSearchForm />
                </MyTabPanel>
                <MyTabPanel value={selectedTab} index={1}>
                  <MySomVictimSearchForm />
                </MyTabPanel>
              </>
            ) : interviewer?.data_entry_purpose?.id == 2 ? (
              <>
                <MyTabPanel value={selectedTab} index={0}>
                  <MyTipCaseSearchForm />
                </MyTabPanel>
                <MyTabPanel value={selectedTab} index={1}>
                  <MySomCaseSearchForm />
                </MyTabPanel>
                <MyTabPanel value={selectedTab} index={2}>
                  <MyTraffickerSearchForm />
                </MyTabPanel>
                <MyTabPanel value={selectedTab} index={3}>
                  <MySmugglerSearchForm />
                </MyTabPanel>
              </>
            ) : interviewer?.data_entry_purpose?.id == 3 ? (
              <>
                <MyTabPanel value={selectedTab} index={0}>
                  <MySomCaseSearchForm />
                </MyTabPanel>
                <MyTabPanel value={selectedTab} index={1}>
                  <MySmugglerSearchForm />
                </MyTabPanel>
               
              </>
            ) : interviewer?.data_entry_purpose?.id == 4 ? (
              <>
                <MyTabPanel value={selectedTab} index={0}>
                  <MyTipAssistanceVictim />
                </MyTabPanel>
                <MyTabPanel value={selectedTab} index={1}>
                  <MySomAssistanceVictim />
                </MyTabPanel>
              </>
            ) : null}
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            {/* <Button onClick={handleCancel} color="primary" sx={{ marginRight: 2 }}>
              {strings.app.cancel}
            </Button> */}
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
};

const innerStyles = {
  checkBoxStyles: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)", // light gray background on hover
    },
    py: 2,
  },
  box: {
    position: "relative",
    width: "80%",
    overflowY: "auto",
    // maxWidth: 400,
    maxHeight: "80vh",
    bgcolor: "background.paper",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
    pb: 4,
  },
};

export default MySomCaseNoSearchForm;
