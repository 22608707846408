import React, { useEffect, useContext, useState, useRef } from "react";
import MyTextInput from "../MyInputs/MyTextInput";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { AuthContext } from "../../App";
import MyCard from "../MyInputs/MyCard";
import MyRadioGroup from "../MyInputs/MyRadioGroup";
import MyYesNoRadioGroup from "../MyInputs/MyYesNoRadioGroup";
import MySelectList from "../MyInputs/MySelectList";
import MyMultipleSelectList from "../MyInputs/MyMultipleSelectList";
import MyCardTitle from "../MyInputs/MyCardTitle";
import { addExploitation, fetchExploitationDetails, getExploitations, updateExploitation, } from "../../api/api";
import MyFormsGridItem from "../MyInputs/MyFormsGridItem";
import styles from "../MyInputs/styles";

const MyExploitationForm = ({ d }) => {
  const formName = "ExploitationForm";
  const parentFormName = "VictimProfileForm";
  const { strings } = useContext(AuthContext);

  useEffect(() => {
    if (!d.isProfileSet) {
      d.setProfile({
        v_id: "",
        subjectToExploitation: "",
        intentToExploit: "",
        exploitationLength: "",
        exploitationAge: "",
        paidDebt: "",
        debtAmount: "",
        freedMethod: "",
        eventDescription: "",
        eProstitution: "",
        eOtherSexual: "",
        eOtherSexualOnline: "",
        eOnlinePorno: "",
        eCriminalActivity: "",
        eCriminalActivityType: [],
        eForcedLabour: "",
        eForcedLabourIndustry: [],
        eForcedMarriage: "",
        eVictimKnewSpouse: "",
        eSpouseNationality: "",
        eBPricePaid: "",
        eBPriceAmountKind: "",
        eBPriceRecipient: [],
        eChildMarriage: "",
        eChildMarriageReason: [],
        eVictimPregnancy: "",
        eChildFromMarriage: "",
        eMaternalHealthIssues: "",
        eMHealthIssuesDescription: "",
        eMarriageViolence: "",
        eMarriageViolenceType: [],
        eForcedMilitary: "",
        eForcedMilitaryType: "",
        eArmedGroupName: "",
        eVictimMilitaryActivities: [],
        eChildSoldier: "",
        eChildSoldierAge: "",
        eOrganRemoved: "",
        eBodyPartRemoved: [],
        eOperationLocation: "",
        eOperationCountry: "",
        eOrganSalePrice: "",
        eOrganPaidTo: "",
        eRemarks: "",
        eRecruitmentType: [],
        eRecruiterRelationship: [],
        eTraffickingMeans: [],
      })
    }

    d.setStrings(strings.exploitation)
    d.setFormName(formName)
    d.setParentFormName(parentFormName)
    d.setIsProfileSet(true);
    d.setGetFormData(() => getExploitations)
    d.setPutFormData(() => updateExploitation)
    d.setPostFormData(() => addExploitation)

    d.setIsFirstForm(false);
    d.setRequiredId("v_id");

  }, []);


  // Effect to update profile.v_id whenever victimId changes
  useEffect(() => {
    // console.log("exploatation form victimId updating to ", d.victimId);
    d.setProfile((prevProfile) => ({
      ...prevProfile,
      v_id: d.victimId,
    }));
  }, [d.victimId]);



  if (!d.isProfileSet) return <div></div>

  return (
    <div>

      <Box height={64} />
      <Box sx={styles.exploitationBorders}>
        <MyCardTitle title={strings.exploitation.exploitationOverview} />
        <Grid container>
          <MyFormsGridItem>
            <MyCard>
              <MyYesNoRadioGroup d={{ name: "subjectToExploitation", ...d }} />
              {d.profile.subjectToExploitation === false ? (
                <MyYesNoRadioGroup d={{ name: "intentToExploit", ...d }} />
              ) : d.profile.subjectToExploitation === true ? (
                <>
                  <MyTextInput
                    d={{
                      name: "exploitationLength",
                      ...d,
                      optionsList: { dbLookupName: "timeUnits", side: "right" },
                    }}
                    inputMode={"number"}
                    inputProps={{ min: 0 }}
                  />
                  <MySelectList d={{ name: "exploitationAge", ...d, dbLookupName: "exploitationAges" }} />
                </>
              ) : (
                <></>
              )}
            </MyCard>

          </MyFormsGridItem>
          <MyFormsGridItem>
            <MyCard>
              <MyYesNoRadioGroup d={{ name: "paidDebt", ...d }} />
              {d.profile.paidDebt === true ? (
                <MyTextInput
                  d={{ name: "debtAmount", ...d, optionsList: { dbLookupName: "currencyCodes", side: "right" } }}
                  inputMode={"number"}
                  inputProps={{ min: 0 }}
                />
              ) : (
                <></>
              )}
            </MyCard>
          </MyFormsGridItem>
          {/* </Grid>

      <Grid container sx={styles.exploitationBorders}> */}
          <MyFormsGridItem>
            <MyCard>
              <MySelectList d={{ name: "freedMethod", ...d, dbLookupName: "freedMethods" }} />
            </MyCard>
          </MyFormsGridItem>
          <MyFormsGridItem>
            <MyCard>
              <MyTextInput d={{ name: "eventDescription", ...d }} multiline rows={4} />
            </MyCard>

          </MyFormsGridItem>
        </Grid>
      </Box>


      <Grid container>
        <MyFormsGridItem>
        </MyFormsGridItem>
        <MyFormsGridItem>
        </MyFormsGridItem>
      </Grid>


      <Box sx={styles.exploitationBorders}>
        {/* <Typography style={{ marginLeft: '32px', padding: '8px 0px', textAlign: 'center', }}>{strings.exploitation.exploitationType}</Typography> */}
        <MyCardTitle title={strings.exploitation.sexualExploitation} />
        <Grid container>
          <MyFormsGridItem>
            <MyCard>
              <MyYesNoRadioGroup d={{ name: "eProstitution", ...d }} />

            </MyCard>
          </MyFormsGridItem>
          <MyFormsGridItem>
            <MyCard>
              <MyYesNoRadioGroup d={{ name: "eOtherSexual", ...d }} />
              {d.profile.eOtherSexual === true ? (
                <>
                  <MyYesNoRadioGroup d={{ name: "eOtherSexualOnline", ...d }} />
                  <MyYesNoRadioGroup d={{ name: "eOnlinePorno", ...d }} />
                </>
              ) : (
                <></>
              )}
            </MyCard>
          </MyFormsGridItem>
        </Grid>
      </Box>

      <Box sx={styles.exploitationBorders}>
        <MyCardTitle title={strings.exploitation.labourAndCriminalExploitation} />
        <Grid container >
          <MyFormsGridItem>
            <MyCard>
              <MyYesNoRadioGroup d={{ name: "eCriminalActivity", ...d }} />
              {d.profile.eCriminalActivity === true ? (
                <>
                  <MyMultipleSelectList
                    d={{ name: "eCriminalActivityType", ...d, dbLookupName: "criminalActivityTypes" }}
                  />
                </>
              ) : (
                <></>
              )}
            </MyCard>
          </MyFormsGridItem>
          <MyFormsGridItem>
            <MyCard>
              <MyYesNoRadioGroup d={{ name: "eForcedLabour", ...d }} />
              {d.profile.eForcedLabour === true ? (
                <>
                  <MyMultipleSelectList
                    d={{ name: "eForcedLabourIndustry", ...d, dbLookupName: "forcedLabourIndustrys" }}
                  />
                </>
              ) : (
                <></>
              )}
            </MyCard>
          </MyFormsGridItem>
        </Grid>
      </Box>


      <Box sx={styles.exploitationBorders}>
        <MyCardTitle title={strings.exploitation.forcedMarriageExploitation} />
        <Grid container >
          <MyFormsGridItem>
            <MyCard>
              <MyYesNoRadioGroup d={{ name: "eForcedMarriage", ...d }} />
              {d.profile.eForcedMarriage === true ? (
                <>
                  <MyYesNoRadioGroup d={{ name: "eVictimKnewSpouse", ...d }} />
                  <MySelectList d={{ name: "eSpouseNationality", ...d, dbLookupName: "countrys" }} />
                  <MySelectList d={{ name: "eBPricePaid", ...d, dbLookupName: "bridePrices" }} />
                </>
              ) : (
                <></>
              )}
              {d.profile.eBPricePaid === d.dbLookups.bridePrices[0]["value"] ||
                d.profile.eBPricePaid === d.dbLookups.bridePrices[1]["value"] ? (
                <>
                  <MyTextInput d={{ name: "eBPriceAmountKind", ...d }} />
                  <MyMultipleSelectList
                    d={{ name: "eBPriceRecipient", ...d, dbLookupName: "bridePriceRecipients" }}
                  />
                </>
              ) : (
                <></>
              )}
            </MyCard>
          </MyFormsGridItem>
          <MyFormsGridItem>
            <MyCard>
              <MyYesNoRadioGroup d={{ name: "eChildMarriage", ...d }} />
              {d.profile.eChildMarriage === true ? (
                <>
                  <MyMultipleSelectList
                    d={{ name: "eChildMarriageReason", ...d, dbLookupName: "childMarriageReasons" }}
                  />
                  <MyYesNoRadioGroup d={{ name: "eVictimPregnancy", ...d }} />
                  {d.profile.eVictimPregnancy === true ? (
                    <>
                      <MyTextInput d={{ name: "eChildFromMarriage", ...d }} inputMode="number" inputProps={{ min: 0 }} />
                      <MyYesNoRadioGroup d={{ name: "eMaternalHealthIssues", ...d }} />
                      {d.profile.eMaternalHealthIssues === true ? (
                        <>
                          <MyTextInput
                            d={{ name: "eMHealthIssuesDescription", ...d }}
                            multiline
                            rows={4}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}

                  <MyRadioGroup d={{ name: "eMarriageViolence", ...d, dbLookupName: "affirmOptions" }} />
                  {d.profile.eMarriageViolence === d.dbLookups.affirmOptions[0]["key"] ? (
                    <>
                      <MyMultipleSelectList
                        d={{ name: "eMarriageViolenceType", ...d, dbLookupName: "marriageViolenceTypes" }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </MyCard>
          </MyFormsGridItem>
        </Grid>
      </Box>

      <Box sx={styles.exploitationBorders}>
        <MyCardTitle title={strings.exploitation.millitaryExploitation} />
        <Grid container >
          <MyFormsGridItem>
            <MyCard>
              <MyYesNoRadioGroup d={{ name: "eForcedMilitary", ...d }} />

              {d.profile.eForcedMilitary === true ? (
                <>
                  <MyRadioGroup d={{ name: "eForcedMilitaryType", ...d, dbLookupName: "militaryTypes" }} />
                  <MyTextInput d={{ name: "eArmedGroupName", ...d }} />
                  <MyMultipleSelectList
                    d={{ name: "eVictimMilitaryActivities", ...d, dbLookupName: "militaryActivitys" }}
                  />
                </>
              ) : (
                <></>
              )}
            </MyCard>
          </MyFormsGridItem>
          <MyFormsGridItem>
            <MyCard>
              <MyYesNoRadioGroup d={{ name: "eChildSoldier", ...d }} />
              {d.profile.eChildSoldier === true ? (
                <>
                  <MyTextInput d={{ name: "eChildSoldierAge", ...d }} inputMode="number" inputProps={{ min: 0 }} />
                </>
              ) : (
                <></>
              )}
            </MyCard>
          </MyFormsGridItem>
        </Grid>
      </Box>


      <Box sx={styles.exploitationBorders}>
        <MyCardTitle title={strings.exploitation.organRemovalExploitation} />
        <MyCard>
          <MyYesNoRadioGroup d={{ name: "eOrganRemoved", ...d }} />
          {d.profile.eOrganRemoved === true ? (
            <>
              <Grid container>
                <MyFormsGridItem>
                  <MyMultipleSelectList d={{ name: "eBodyPartRemoved", ...d, dbLookupName: "bodyParts" }} />
                </MyFormsGridItem>
              </Grid>

              <Grid container>
                <MyFormsGridItem>
                  <MySelectList
                    d={{ name: "eOperationLocation", ...d, dbLookupName: "operationLocations" }}
                  />
                </MyFormsGridItem>
                <MyFormsGridItem>
                  <MySelectList d={{ name: "eOperationCountry", ...d, dbLookupName: "countrys" }} />
                </MyFormsGridItem>
              </Grid>


              <Grid container>
                <MyFormsGridItem>
                  <MyTextInput
                    d={{
                      name: "eOrganSalePrice",
                      ...d,
                      optionsList: { dbLookupName: "currencyCodes", side: "right" },
                    }}
                    inputMode={"number"}
                    inputProps={{ min: 0 }}
                  />
                </MyFormsGridItem>
                <MyFormsGridItem>
                  <MySelectList d={{ name: "eOrganPaidTo", ...d, dbLookupName: "organPaidTos" }} />
                </MyFormsGridItem>
              </Grid>

            </>
          ) : (
            <></>
          )}
        </MyCard>

      </Box>

      <Box sx={styles.exploitationBorders}>
        <MyCardTitle title={strings.exploitation.recruitmentExploitation} />
        <Grid container >
          <MyFormsGridItem>
            <MyCard>
              <MyMultipleSelectList d={{ name: "eRecruitmentType", ...d, dbLookupName: "recruitmentTypes" }} />
              <MyMultipleSelectList
                d={{ name: "eRecruiterRelationship", ...d, dbLookupName: "recruiterRelationships" }}
              />
            </MyCard>
          </MyFormsGridItem>
          <MyFormsGridItem>
            <MyCard>
              <MyMultipleSelectList
                d={{ name: "eTraffickingMeans", ...d, dbLookupName: "traffickingMeans" }}
              />
              <MyTextInput d={{ name: "eRemarks", ...d }} multiline rows={4} />
            </MyCard>
          </MyFormsGridItem>
        </Grid>
      </Box>


    </div>
  );
};

export default MyExploitationForm;
