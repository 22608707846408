import React, { useContext, useEffect, useState } from "react";

import { Tabs, Tab, Box, Button, Container, Typography } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import styles from "../components/MyInputs/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../App";
import MyGeneralForm from "../components/MyForms/MyGeneralForm";
import MyVictimProfileForm from "../components/MyForms/MyVictimProfileForm";
import MyTabPanel from "../components/MyInputs/MyTabPanel";
import MyExploitationForm from "../components/MyForms/MyExploitationForm";
import MyTransistRouteForm from "../components/MyForms/MyTransistRouteForm";
import { theme, navigationRoutes } from "../utils/variables";
import MyConsentForm from "../components/MyConsentForm";
import MyVictimSearchForm from "../components/searchForms/MyVictimSearchForm";
import MySomVictimSearchForm from "../components/searchForms/MySomVictimSearchForm";
import MyTipCaseSearchForm from "../components/searchForms/MyTipCaseSearchForm";
import MySomCaseSearchForm from "../components/searchForms/MySomCaseSearchForm";
import MyTipAssistanceVictim from "../components/searchForms/MyTipAssistanceVictim";
import MySomAssistanceVictim from "../components/searchForms/MySomAssistanceVictim";
import MyTraffickerSearchForm from "../components/searchForms/MyTraffickerSearchForm";
import MySmugglerSearchForm from "../components/searchForms/MySmugglerSearchForm";

const SearchScreen = ({ d }) => {
  const { strings, interviewer } = useContext(AuthContext);
  const [selectedTab, setSelectedTab] = useState(0);
  const [formsUploaded, setFormsUploaded] = useState({});
  const [consentGiven, setConsentGiven] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const { isUpdateMode, isViewMode, isCreateMode, victimId, caseId } = location.state || {};

  const [tabsVictimId, setTabsVictimId] = useState(victimId);
  const [tabsCaseId, setTabsCaseId] = useState(caseId);

  // console.log(location);
  // console.log("isView ",isUpdateMode," isUpdate ", isViewMode);
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const goBackHome = () => {
    navigate(navigationRoutes.tipCases);
  };

  const goNextTab = (index) => {
    setSelectedTab(index);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const data = {
    victimId: tabsVictimId,
    caseId: tabsCaseId,
    setVictimId: setTabsVictimId,
    setCaseId: setTabsCaseId,
    isUpdateMode: isUpdateMode,
    isViewMode: isViewMode,
    formsUploaded: formsUploaded,
    setFormsUploaded: setFormsUploaded,
  };

  const [tabTitles] = useState(
    interviewer?.data_entry_purpose?.id == 1
      ? [
          { key: "tipVictim", title: strings.appMenu.tip },
          { key: "somVictim", title: strings.appMenu.som },
        ]
      : interviewer?.data_entry_purpose?.id == 2
      ? [
          { key: "tipCase", title: strings.addTip.tipCase },
          { key: "somCase", title: strings.addSom.somCase },
          // { key: "tipSuspect", title: strings.addTip.suspects },
          // { key: "somSuspect", title: strings.addSom.suspects },
        ]
      : interviewer?.data_entry_purpose?.id == 3
      ? [
          { key: "tipCase", title: strings.addTip.tipCase },
          { key: "somCase", title: strings.addSom.somCase },
          // { key: "tipSuspect", title: strings.addTip.suspects },
          // { key: "somSuspect", title: strings.addSom.suspects },
        ]
      : [
          { key: "tipVictim", title: strings.appMenu.tip },
          { key: "somVictim", title: strings.appMenu.som },
        ]
  );

  return (
    <Container>
      {isCreateMode && <MyConsentForm open={!consentGiven} handleOk={() => setConsentGiven(true)} handleCancel={() => goBackHome()} />}
      <Box>
        <Box position="sticky" top={0} style={{ borderBottom: "1px solid #ccc", zIndex: "10" }} bgcolor="background.paper">
          <Tabs value={selectedTab} onChange={handleChange} centered>
            {tabTitles.map((item, index) => (
              <Tab key={index} label={<Box sx={{ display: "flex", alignItems: "center" }}>{item.title}</Box>} />
            ))}
          </Tabs>
        </Box>
        {interviewer?.data_entry_purpose?.id == 1 ? (
          <>
            <MyTabPanel value={selectedTab} index={0}>
              <MyVictimSearchForm />
            </MyTabPanel>
            <MyTabPanel value={selectedTab} index={1}>
              <MySomVictimSearchForm />
            </MyTabPanel>
          </>
        ) : interviewer?.data_entry_purpose?.id == 2 ? (
          <>
            <MyTabPanel value={selectedTab} index={0}>
              <MyTipCaseSearchForm />
            </MyTabPanel>
            <MyTabPanel value={selectedTab} index={1}>
              <MySomCaseSearchForm />
            </MyTabPanel>
            <MyTabPanel value={selectedTab} index={2}>
              <MyTraffickerSearchForm />
            </MyTabPanel>
            <MyTabPanel value={selectedTab} index={3}>
              <MySmugglerSearchForm />
            </MyTabPanel>
          </>
        ) : interviewer?.data_entry_purpose?.id == 3 ? (
          <>
            <MyTabPanel value={selectedTab} index={0}>
              <MyTipCaseSearchForm />
            </MyTabPanel>
            <MyTabPanel value={selectedTab} index={1}>
              <MySomCaseSearchForm />
            </MyTabPanel>
            <MyTabPanel value={selectedTab} index={2}>
              <MyTraffickerSearchForm />
            </MyTabPanel>
            <MyTabPanel value={selectedTab} index={3}>
              <MySmugglerSearchForm />
            </MyTabPanel>
          </>
        ) : interviewer?.data_entry_purpose?.id == 4 ? (
          <>
            <MyTabPanel value={selectedTab} index={0}>
              <MyTipAssistanceVictim />
            </MyTabPanel>
            <MyTabPanel value={selectedTab} index={1}>
              <MySomAssistanceVictim />
            </MyTabPanel>
          </>
        ) : null}
      </Box>
    </Container>
  );
};

export default SearchScreen;
