import { addVictim } from "../api/api";

const pt = {
  app: {
    app_language: "Linguagem",
    login: "Conecte-se",
    saveAndProceed: "Salvar e prosseguir",
    saveAndClose: "Salvar e fechar",
    cancel: "Cancelar",
    view: "visualizar",
    modify: "modificar",
    submit: "Enviar",
    updateDbLookups: "Atualizar dados do aplicativo",
    search: "Procurar",
    advancedSearch: "Procurar avançada",
  },
  appMenu: {
    tip: "Tráfico de Pessoas",
    som: "Contrabando de Migrantes",
    aggregates: "Agregar dados TIP",
    offlineSync: "Sincronização off-line",
    findHelp: "Encontrar ajuda",
    settings: "configurações",
    dashboard: "Painel de Controle",
    search: "Pesquisar",
    profile: "Perfil",
  },
  dashboard: {
    victims: "Vítimas",
    suspects: "Suspeitos",
    prosecutions: "Processos",
    convictions: "Condenações",
    victimDemographics: "Dados demográficos das vítimas",
    exploitationAnalysis: "Análise de exploração",
    suspectDemographics: "Dados demográficos dos suspeitos",
    suspectProsecutionAndConviction: "Suspeito, processos e condenações",
    byGender: "Por Gênero",
    byNationality: "Por Nacionalidade",
  },
  authMenu: {
    login: "TIP-SOM",
  },
  tipMenu: {
    victims: "Vítimas",
    suspects: "Suspeitos",
    consent: "Consentimento",
    addVictim: "Formulário de Vítima",
    viewVictim: "Detalhes da vítima",
  },
  somMenu: {
    victims: "Vítimas",
    suspects: "Suspeitos",
    consent: "Consentimento",
    addVictim: "Formulário de caso",
    viewVictim: "Detalhes do caso",
  },
  login: {
    title: "Conecte-se",
    username: "Nome de usuário",
    email: "Endereço de e-mail",
    password: "Senha",
    selectOption: "Selecione a opção",
    register: "Registrar",
    forgotPassword: "Esqueceu a senha?",
    registrationSuccess: "Sucesso",
    registrationSuccessDescription: "Conta registrada com sucesso \nPor favor, prossiga para o login",
  },
  logout: {
    logoutTitle: "Sair",
    logoutText: "Tem certeza que deseja sair?",
    logoutButton: "Sair",
  },
  victimScreen: {
    victimsTitle: "Vítimas",
    suspectsTitle: "Suspeitos",
  },
  victimCard: {
    exploitationForm: "Dados de exploração",
    transitForm: "Dados de trânsito",
    investigationForm: "Dados de investigação",
    suspectForm: "Dados suspeitos",
    prosecutionForm: "Dados de acusação",
    assistanceForm: "Dados de assistência",
    socioForm: "Dados de socioeconômicas",
  },
  victimDetails: {
    caseInterview: "Entrevista de caso",
  },
  addTip: {
    victimProfile: "Perfil da vítima",
    exploitation: "Tráfico de Humanos",
    transitRoute: "Rota de trânsito",
    investigations: "Investigações",
    suspects: "Suspeito de traficante",
    suspect: "Suspeito",
    prosecutions: "Processos",
    assistance: "Tipos de Assistência",
    socio: "Socioeconómico antes do tráfico",
    aggregate: "Dados agregados sobre Assistência",
    case: "Dados do caso",
    addNewVictim: "Adicionar Vítima de TIP",
    addNewCase: "Adicionar Caso TIP",
    tipCase: "Adicionar Caso TIP",
    searchCaseNumber: "Procurar Caso",
  },
  addSom: {
    victimProfile: "Perfil da vítima",
    transitRoute: "Rota de trânsito",
    investigations: "Investigações",
    suspects: "Contrabandista Suspeito",
    prosecutions: "Processos",
    assistance: "Tipos de Assistência",
    socio: "Socioeconómico antes do tráfico",
    aggregate: "Dados agregados sobre Assistência",
    case: "Dados do caso",
    addNewVictim: "Adicionar Vítima SOM",
    addNewCase: "Adicionar Caso de SOM",
    somCase: "Adicionar Caso SOM",
  },
  consent: {
    shareData:
      "A vítima foi informada de que (nome da organização/departamento) reserva-se o direito de partilhar os dados do seu caso individual para fins de assistência e apenas com departamentos governamentais e organizações parceiras envolvidas na assistência direta?",
    limitedDisclosure:
      "A vítima foi ainda informada de que (nome da organização/departamento) reserva-se o direito de fazer uma divulgação limitada de dados não pessoais com base nas informações recolhidas na entrevista às autoridades policiais, com a finalidade de resgatar outras vítimas que permanecem sob o controlo dos traficantes ou impedir que outras vítimas potenciais sejam traficadas?",
    anonymousDataUse:
      "A vítima foi ainda informada de que (nome da organização/departamento) reserva-se o direito de utilizar dados (apenas anónimos e agregados) para fins de investigação?",
    rightToRefuse: "A vítima tem o direito de recusar ou abster-se de responder respondendo Não aplicável (NA) ou Não sei (NK)",
    minorOrImpairment: "A vítima é menor de idade ou uma pessoa com deficiências físicas ou cognitivas?",
    dataCollectorNoteTitle: "NOTA AO COLETOR DE DADOS",
    dataCollectorNote1:
      "1. Dados de Migrantes Contrabandeados envolvem múltiplas vítimas em comparação com vítimas de Tráfico, portanto, a entrada de dados permite dados coletivos (entrada de dados de vítimas SOM únicas e múltiplas)",
    dataCollectorNote2: "2. Os dados serão agregados em conjuntos de 10 anos",
  },
  profileForm: {
    firstName: "Nome",
    familyName: "Sobrenome",
    position: "Cargo",
    organization: "Autoridade/Organização",
    address: "Endereço físico",
    email: "Endereço de e-mail",
    country: "País",
    dataEntryPurpose: "Finalidade da entrada de dados",
    password: "Senha",
    confirmPassword: "Confirmar Senha",
    leavePasswordBlank: "Deixe em branco para manter a senha atual",
    interviewerInformation: "Informações do entrevistador",
    interviewerRegistration: "Registro de Entrevistador",
  },
  decisionPopup: {
    singleVictim: "Vítima Única",
    multipleVictim: "Múltiplas Vítimas",
  },
  victim: {
    initials: "Iniciais",
    email: "E-mail",
    age: "Idade",
    dob: "Data de nascimento",
    gender: "Sexo",
    race: "Corrida",
    religions: "Religião",
    citizenship: "Cidadania",
    dualCitizenship: "país de dupla cidadania",
    languages: "línguas",
    idType: "Tipo de identificação",
    idNumber: "Número de identidade",
    address: "Endereço",
    lastPlaceOfResidence: "Último local de residência",
    countryOfBirth: "País de nascimento",
    placeOfBirth: "Local de nascimento",
    interviewDate: "Data da entrevista",
    interviewerCountry: "País do entrevistador",
    interviewerLocation: "Localização do entrevistador",
    additionalRemarks: "Observações adicionais",
    dateStart: "Data de início da entrevista",
    dateEnd: "Data de fim da entrevista",
  },
  victimBulk: {
    numberOfVictims: "Quantas vítimas existem?",
    genders: "Qual é a sua distribuição por sexo?",
    ages: "Qual é a distribuição etária das vítimas?",
    races: "Quais raças são as vítimas?",
    religions: "Religião",
    languages: "Que línguas eles falam?",
    countries: "País(es) de origem suspeito(s)",
    interviewDate: "Data da entrevista",
    interviewerCountry: "País do Entrevistador",
    interviewerLocation: "Localização do entrevistador",
    additionalRemarks: "Observações adicionais",
  },
  caseData: {
    dateOfArrest: "Data da prisão",
    roleInTrafficking: "Papel no tráfico",
    countryFrom: "País de",
    placeFrom: "Lugar de",
    countryTo: "País para",
    placeTo: "Lugar para",
    caseNumber: "Número do caso",
    additionalRemarks: "Observações adicionais",
  },
  exploitation: {
    subjectToExploitation: "A vítima foi sujeita a exploração",
    intentToExploit: "Se NÃO, houve intenção de exploração?",
    exploitationLength: "Se SIM, durante quanto tempo a vítima foi vítima de exploração?",
    exploitationAge: "Se SIM, qual(is) a(s) idade(s) da vítima durante o período de exploração?",
    paidDebt: "A vítima teve que pagar uma dívida aos seus exploradores?",
    debtAmount: "Se SIM, especifique - Montante total?",
    freedMethod: "Como a vítima foi libertada?",
    eventDescription: "Descreva o que realmente aconteceu",
    exploitationType: "Que tipo(s) de exploração melhor descrevem as experiências da vítima?",
    eProstitution: "Prostituição",
    eOtherSexual: "Outra exploração sexual",
    eOtherSexualOnline: "Exploração sexual on-line",
    eOnlinePorno: "Produção de material pornográfico online",
    eCriminalActivity: "Atividade criminal",
    eCriminalActivityType: "Se SIM, que crime?",
    eForcedLabour: "Trabalho forçado",
    eForcedLabourIndustry: "Se SIM, qual setor?",
    eForcedMarriage: "Casamento forçado",
    eVictimKnewSpouse: "A vítima conhecia o cônjuge antes do casamento?",
    eSpouseNationality: "Qual é a nacionalidade do cônjuge?",
    eBPricePaid: "Foi pago o preço da noiva, dote ou dote?",
    eBPriceAmountKind: "Se SIM para preço da noiva, dote ou dote, quanto e/ou quais bens materiais?",
    eBPriceRecipient: "Se SIM para o preço da noiva, qual(is) indivíduo(s) recebeu(m) o preço da noiva?",
    eChildMarriage: "Casamento infantil",
    eChildMarriageReason: "WQual foi o motivo do casamento infantil?",
    eVictimPregnancy: "A vítima engravidou em consequência do casamento?",
    eChildFromMarriage: "Se SIM, quantos filhos nasceram do casamento?",
    eMaternalHealthIssues: "A vítima tinha algum problema de saúde materna?",
    eMHealthIssuesDescription: "Descreva o problema de saúde",
    eMarriageViolenceType: "Se sim, por favor especifique",
    eMarriageViolence: "Durante o casamento a vítima foi submetida a alguma forma de violência?",
    eForcedMilitary: "Militares forçados",
    eForcedMilitaryType: "Tipo militar forçado",
    eArmedGroupName: "Qual era o nome do grupo armado? Por favor especifique",
    eVictimMilitaryActivities: "Que tipo de atividades a vítima realizava?",
    eChildSoldier: "Criança soldado",
    eChildSoldierAge: "Em que idade",
    eOrganRemoved: "Remoção de órgãos",
    eBodyPartRemoved: "Quais partes do corpo foram removidas?",
    eOperationLocation: "Onde foi realizada a operação?",
    eOperationCountry: "Em que país foi realizada a operação?",
    eOrganSalePrice: "Se o órgão foi vendido, quanto foi oferecido ou efetivamente pago pelo órgão removido?",
    eOrganPaidTo: "Se aplicável, a quem foi pago o dinheiro?",
    eRemarks: "Observações adicionais",
    eRecruitmentType: "Em caso de recrutamento, como a vítima foi recrutada?",
    eRecruiterRelationship: "Qual era o relacionamento da vítima com o recrutador?",
    eTraffickingMeans: "Quais foram os meios empregados no processo de tráfico?",
  },
  prosecution: {
    suspectedTraficker: "Suspeito",
    caseStatus: "Qual é o status do caso?",
    trialCourt: "Em que tribunal de primeira instância está inscrito este processo?",
    foreignCourtCountry: "Se for num tribunal estrangeiro, especifique o país:",
    courtCaseNumber: "Qual é o número do processo judicial atribuído?",
    verdict: "Se aplicável, qual foi o veredicto:",
    guiltyVerdict: "Se CULPADO, especifique:",
    prosecutionOutcome: "Qual foi o resultado da acusação?",
    acquittalReason: "Em caso de ABSOLVIÇÃO, indicar os motivos:",
    reviewAppealOutcome: "Se REVISÃO/RECURSO, qual foi o resultado?",
    penalty: "Qual foi a natureza da sanção/penalidade?:",
    yearsImposed: "Se SANÇÃO/PENALIDADE DE CUSTODIAL, quantos anos foram impostos?:",
  },
  suspect: {
    firstName: "Primeiro nome",
    surName: "Sobrenome",
    dob: "Data de nascimento",
    age: "Idade",
    gender: "Sexo",
    race: "Corrida",
    religions: "Religião",
    languages: "línguas",
    citizenship: "Cidadania",
    dualCitizenship: "país de dupla cidadania",
    nationality: "Nacionalidade",
    idType: "Tipo de identificação",
    idNumber: "Número de identidade",
    address: "Endereço",
    lastPlaceOfResidence: "Último local de residência",
    countryOfBirth: "País de nascimento",
    placeOfBirth: "Local de nascimento",
  },
  tipAgg: {
    dataSupplier: "Qual foi a natureza da sanção/penalidade?",
    totalTip: "Número total estimado de casos de assistência ao TP durante o último ano civil",
    totalVictim: "Número total estimado de casos de assistência ao TP durante o último ano civil",
    totalFamily: "Número total de vítimas confirmadas e/ou potenciais que receberam serviços",
    totalAnon:
      "Número total de contactos anónimos recebidos através de uma linha direta, chat online, através de sindicatos ou inspetores do trabalho, ou através de cadeias de valor de empresas privadas",
  },
  arrest: {
    suspectArrested: "Algum suspeito foi preso até o momento?",
    whyNoArrest: "Se NÃO, explique por que não houve prisões?",
    organizedCrime: "O suspeito tem ligações com o crime organizado?",
    victimSmuggled: "A vítima viajava ou utilizava os serviços de traficantes de migrantes?:",
    howTraffickersOrg: "Como o(s) traficante(s) está(ão) organizado(s)?",
    investigationDone: "Foi realizada uma investigação?",
    whyNoInvestigation: "Se NÃO, especifique por quê?",
    investigationStatus: "Se SIM, qual é o status da investigação?",
    withdrawnClosedReason: "Se RETIRADO ou ARQUIVO DE CASO FECHADO, especifique?",
    whyPending: "Se PENDENTE, especifique?",
  },
  arrestSom: {
    suspectArrested: "Algum suspeito foi preso até o momento?",
    whyNoArrest: "Se NÃO, explique por que não houve prisões?",
    organizedCrime: "O suspeito tem ligações com o crime organizado?",
    victimSmuggled: "A vítima viajava ou contrabandistas os serviços de traficantes de migrantes?:",
    howTraffickersOrg: "Como o(s) traficante(s) está(ão) organizado(s)?",
    investigationDone: "Foi realizada uma investigação?",
    whyNoInvestigation: "Se NÃO, especifique por quê?",
    investigationStatus: "Se SIM, qual é o status da investigação?",
    withdrawnClosedReason: "Se RETIRADO ou ARQUIVO DE CASO FECHADO, especifique?",
    whyPending: "Se PENDENTE, especifique?",
  },
  assistance: {
    duration: "Duração",
    providedBy: "Fornecido por",

    socialAssistance: "Social Assistance",

    medicalRehabilitationAssistance: "Assistência Médica de Reabilitação",

    housingAllowance: "Subsídio de Habitação",

    halfwayHouse: "Casa de recuperação",

    shelter: "Abrigo",

    vocationalTraining: "Formação Profissional",

    incomeGeneratingProject: "Projeto de geração de renda",

    legalAssistance: "Assistência legal",

    medicalAssistance: "Assistência médica",

    financialAssistance: "Assistência financeira",

    educationAssistance: "Assistência educacional",

    immEmmigrationAssistance: "Assistência à imigração/emigração",

    communityBasedAssistance: "Outra assistência baseada na comunidade",
  },
  yesNo: {
    yes: "Sim",
    no: "Não",
  },
  gender: {
    male: "Macho",
    female: "Fêmea",
    other: "Outro",
  },
  socio: {
    familyStructure: "Qual era a estrutura da família de origem no momento do incidente de tráfico?",
    livingWith: "Se a vítima não vivia com a sua família antes de entrar no processo de tráfico, com quem vivia antes da partida?",
    violence: "Houve alguma violência no ambiente social ou familiar onde a vítima vivia antes da partida?",
    educationLevel: "Qual foi o último nível de escolaridade recebido?",
    lastOccupation: "Qual foi a última atividade/ocupação que a vítima exerceu antes de sair do local de origem?",
  },
  tipTransit: {
    countryOfOrigin: "País de Origem ou Recrutamento",
    countryOfDestination: "País de Destino ou Exploração",
    countriesOfTransit: "Países de Trânsito",
    countryOfInterception: "País de Interceptação",
    meansOfTransportation: "Meios de Transporte",
    additionalRemarks: "Observações Adicionais",
    cityVillageOfOrigin: "Cidade/Vila de Origem ou Recrutamento",
    cityVillageOfDest: "Cidade/Vila de Destino ou Exploração",
  },
  somTransit: {
    countryOfOrigin: "País de origem",
    countryOfDestination: "País de destino",
    countriesOfTransit: "Países de trânsito",
    countryOfInterception: "País de interceptação",
    meansOfTransportation: "Meio de transporte",
    additionalRemarks: "Observações adicionais",
    cityVillageOfOrigin: "Cidade/Vila de origem",
    cityVillageOfDest: "Cidade/Vila de destino",
  },
  cardDetails: {
    dateCreated: "Criado em",
  },
  findHelp: {
    searchString: "Digite para pesquisar",
  },
  dataValidations: {
    helperTexts: {
      invalidEmail: "Email inválido",
      invalidNumber: "Insira apenas um número",
      minNumber: "O valor mínimo deve ser",
      maxNumber: "O valor máximo deve ser",
      totalCountError: "O total dos valores excede o número de vítimas",

      passwordsMustMatch: "As senhas devem coincidir",
      passwordMustContain: "A senha deve conter",
      passwordALowerCase: "pelo menos uma letra minúscula",
      passwordAUpperCase: "pelo menos uma letra maiúscula",
      passwordADigit: "pelo menos um dígito",
      passwordASpecialCharacter: "pelo menos um caractere especial",
      passwordMinLength: "pelo menos 8 caracteres",
      resetTokenInvalid: "Insira um token de redefinição válido",
    },
    otherField: "Outro",
    enterOtherField: "Especifique outro",
    pleaseWait: "Por favor, aguarde",
    checkFormErrors: "Verifique primeiro os erros do formulário",
    solveCaptcha: "Resolva o Captcha Primeiro",
  },
  notifications: {
    noSearchResultsFindHelp: "Nenhum resultado de pesquisa encontrado",
    uploadSuccess: "Dados salvos com sucesso",
    uploadAllSuccess: "Todos os formulários foram carregados com sucesso",
    uploadError: "Erro ao carregar o formulário",
    fillPreviousForms: " Preencha os formulários anteriores primeiro",
    updateSuccess: "Dados do aplicativo atualizados com sucesso",
    offlineHeader: "Offline",
    offlineText: "Você não está conectado à Internet.",
    successHeader: "Sucesso",
    pendingFormsExist: "Formulários pendentes de upload. Conecte-se à internet e clique em carregar",
    noPendingForms: "Você não tem formulários pendentes para upload",
    uploadingForms: "Carregando Formulários",
    appDataUpdatedSuccess: "Dados do aplicativo atualizados com sucesso",
    loginFailHeader: "Falha no login",
    loginFailMessage: "Nome de usuário ou senha inválidos",
    noSearchResults: "Nenhum resultado de pesquisa encontrado",
    pleaseCreateANewCase: "Por favor, crie um novo caso",
  },
  selectList: {
    placeholder: "Selecionar opção",
    searchPlaceholder: "Pesquisar",
    selectedTextPlaceholder: "Selecione",
    notFoundText: "Nenhum dado encontrado",
  },
  datePicker: {
    placeholder: "Selecionar data",
    label: "Data",
    confirmLabel: "Confirmar",
    cancelLabel: "Cancelar",
  },
};

export default pt;
