import React, { useEffect, useContext, useState, useRef } from "react";
import MyTextInput from "../MyInputs/MyTextInput";
import { AuthContext } from "../../App";
import MyCard from "../MyInputs/MyCard";
import MyDatePickerInput from "../MyInputs/MyDatePicker";
import MySelectList from "../MyInputs/MySelectList";
import MyCardTitle from "../MyInputs/MyCardTitle";
import { addSomCase, addTipCase, addVictim, fetchSomCaseDetails, fetchTipCaseDetails, updateSomCase, updateTipCase } from "../../api/api";
import { Grid } from "@mui/material";
import MyFormsGridItem from "../MyInputs/MyFormsGridItem";

const MyCaseForm = ({ d }) => {
  const formName = "CaseDataForm";
  const { strings } = useContext(AuthContext);

  useEffect(() => {
    if (!d.isProfileSet) {
      d.setProfile({
        dateOfArrest: "",
        caseNumber: "",
        // roleInTrafficking:null,
        countryFrom: "",
        placeFrom: "",
        countryTo: "",
        placeTo: "",
        approval: "",
        approvalComments: "",
      })
    }

    d.setStrings(strings.caseData)
    d.setFormName(formName)
    d.setSetShouldSetCaseId(true);

    d.setIsProfileSet(true);
    d.setGetFormData(() => fetchTipCaseDetails)
    d.setPutFormData(() => updateTipCase)
    d.setPostFormData(() => addTipCase)

  }, []);

  if (!d.isProfileSet) return <div></div>

  return (
    <div>
      <MyCard>
        <MyCardTitle title={strings.caseData.bioData} />
        <Grid container>
          <MyFormsGridItem>
            <MyDatePickerInput d={{ name: "dateOfArrest", ...d }} />
            <MySelectList d={{ name: "countryFrom", ...d, dbLookupName: "countrys" }} />
            <MySelectList d={{ name: "countryTo", ...d, dbLookupName: "countrys" }} />
          </MyFormsGridItem>
          <MyFormsGridItem>
            <MyTextInput d={{ name: "caseNumber", ...d }} />
            <MyTextInput d={{ name: "placeFrom", ...d }} />
            <MyTextInput d={{ name: "placeTo", ...d }} />
          </MyFormsGridItem>
        </Grid>


      </MyCard>

      {d.interviewer?.can_switch_profiles && 
        <MyCard>
          <MyCardTitle title={strings.victim.approval} />
          <Grid container>
            <MyFormsGridItem><MySelectList d={{ name: "approval", ...d, dbLookupName: "approvalStatuses"}} overrideLabel={strings.victim.approval} /></MyFormsGridItem>
            <MyFormsGridItem><MyTextInput d={{ name: "approvalComments", ...d }} overrideLabel={strings.victim.approvalComments}   multiline rows={4} /></MyFormsGridItem>
          </Grid>
        </MyCard>
      }

    </div>
  );
};

export default MyCaseForm;
